.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.loginPage {
  background-color: #FFAD4F;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

div.loginBox {
  background-color: white;
  width: 30vw;
  padding: 2vh 2vw;
  margin: 33vh 33vw;
  font-size: 1vw;
  text-align: center;
  border-radius: 1vh;
}

input.login {
  line-height: 1vw;
  font-size: 1vw;
  margin: 5px;
  border-radius: 5px;
  border-color: black;
}

button.login {
  background-color: darkblue;
  color: white;
  width: 20vw;
  padding: 10px;
  border-radius: 5px;
  line-height: 1vw;
  font-size: 1vw;
  font-weight: 900;
  margin-top: 5vh;
}

h3.error {
  color: darkred;
}
