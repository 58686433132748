div.logTable {
  background-color: darkorange;
  color: white;
  border: 2px solid black;
  width: 70vw;
  height: 80vh;
  padding: 0;
  margin: 0;
  overflow: scroll;
  max-height: 80vh;
  user-select: none;
}

div.logRow {
  border: 1px solid black;
  width: 70vw;
  padding: 0;
  margin: 0;
  left: 0;
}

div.logRow :hover {
  background-color: orange;
}

div.failLogRow {
  background-color: darkred;
  border: 1px solid black;
  width: 70vw;
  padding: 0;
  margin: 0;
  left: 0;
}

div.filters {
  background-color: rgb(37, 37, 94);
  padding: 0;
  height: 60px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

select.filterField {
  margin: 10px;
  padding: 0;
  height: 40px;
  font-size: larger;
  align-items: center;
  text-align: center;
  border-width:0px;
  border:none;
  border-radius: 5px;
}

input.filterField {
  background-color: white;
  margin: 0;
  outline: none;
  align-items: center;
  text-align: center;
  line-height: 40px;
  margin:5px;
  border-width:0px;
  border:none;
  border-radius: 5px;
  font-size: large;
  padding: 0;
  width: 9vw;
}

input.filterFieldDate {
  line-height: 40px;
  margin: 5px;
  border-width:0px;
  border:none;
  text-align: center;
  align-items: center;
}

label.filterLabel {
  color: white;
}
